<template>
  <v-col v-bind="$attrs">
    <PromiseContainer :promises.sync="promises">
      <v-row>
        <v-col :cols="getColsByHorizontal">
          <v-autocomplete v-bind="$attrs" v-model="value.client" :items="clients" item-text="clientName" :item-value="v => v" :rules="getRules" 
            label="Klantnaam" @input="setClient" :required="required" :disabled="disabled" :value-comparator="compareById">
            <template v-slot:item="slotProps">
              <v-list-item-content :class="{'notranslate': slotProps.item.id != 'All'}" v-text="slotProps.item.clientName"></v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col  v-if="displayProjectSelect" :cols="getColsByHorizontal">
          <v-autocomplete v-bind="$attrs" v-model="value.project" :items="getProjects" no-data-text="Selecteer eerst een klant" @input="setProject" 
          :required="required" :disabled="disabled"
                    item-text="projectName" :item-value="v => v" :rules="getRules" label="Projectnaam" :value-comparator="compareById">
            <template v-slot:item="slotProps">
              <v-list-item-content :class="{'notranslate': slotProps.item.id != 'All'}" v-text="slotProps.item.projectName"></v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </PromiseContainer>
  </v-col>
</template>

<script>
import PromiseContainer from "@/components/shared/PromiseContainer"
import { sortListAlphabeticallyByParameter } from "@/shared/utils/CollectionUtils"

export default {
  name: "CustomClientAndProjectSelect.vue",
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: Object,
    rules: Array,
    autoFillClientId: {
      type: String,
      default: null
    },
    autoFillProjectId: {
     type: String,
     default: null
    },
    all: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    includeArchived: {
      type: Boolean,
      default: false,
      required: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    showInActive: {
      type: Boolean,
      required: false,
      default: false
    },
    showAllClients: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  components: {
    PromiseContainer,
  },
  events: ["update:autoFillClientId", "update:autoFillProjectId"],
  data() {
    return {
      promises:[],
      fetchedClients: []
    }
  },
  methods: {
    setClient(event) {
      if (event === null) {
        return
      }
      this.$emit("update:autoFillClientId", event.id)
      this.$emit("update:autoFillProjectId", null)
      if (event.id === "All") {
        this.$set(this.value, 'project', {projectName: "Alle", id: "All"})
      } else {
        this.$set(this.value, 'project', null)
      }
    },
    setProject(event) {
      if (event === null) {
        return
      }
      this.$emit("update:autoFillProjectId", event.id)
    },
    // Currently autoFillClient and autoFillProject both spam 6 time when opening a page with custom client select. Needed to fix bad data loading in for BUILDBASE-1096
    // TODO? The autofill function might be overdue for a refactor or rework. ref: BUILDBASE-1098
    autoFillClient() {
      let clientIsSet = !!this.value.client

        if (this.autoFillClientId) {
          clientIsSet = true
          this.$set(this.value, 'client', this.clients.find((c) => c.id == this.autoFillClientId))
        } else if (this.all) {
          clientIsSet = true
          this.$set(this.value, 'client', {clientName: "Alle", id: "All"})
        }

      return clientIsSet
    },
    autoFillProject(clientIsSet) {
      if (clientIsSet && this.value.client?.id !== "All") {
        if (this.autoFillProjectId) {
          this.$set(this.value, 'project', this.getProjects.find((p) => p.id == this.autoFillProjectId))
        } else if (this.all) {
          this.$set(this.value, 'project', {projectName: "Alle", id: "All"})
        }
      }
    },
    compareById(a, b) {
      return a?.id == b?.id
    },
    doAutoFills() {
      const clientIsSet = this.autoFillClient()
      this.autoFillProject(clientIsSet)
    },
    getUserId() {
      return this.$route.params.userId ? this.$route.params.userId : this.$store.state.authModule.user.id
    },
    filterInactiveList(list) {
      // can filter both clients and projects on .active
      if (this.showInActive) {
        return list
      }
      return list.filter(item => item.active)
    },
  },
  computed: {
    // Collect all clients linked to this organization.
    clients() {
      const filteredClients = this.filterInactiveList([...this.fetchedClients])
      const sortedClients = sortListAlphabeticallyByParameter(filteredClients, "clientName")
      if (this.all) {
        sortedClients.unshift({clientName: "Alle", id: "All"})
      }
      return sortedClients
    },
    // Collects the list of projects for selected client.
    getProjects() {
      const projects = this.value.client && this.value.client.projects ? [...this.value.client.projects] : []
      const filteredProjects = this.filterInactiveList(projects)
      const sortedProjects = sortListAlphabeticallyByParameter(filteredProjects, "projectName")
      if (this.all) {
        sortedProjects.unshift({projectName: "Alle", id: "All"})
      }
      return sortedProjects
    },
    // Checks if the drop down is visually filled in (checks data not visual).
    getRules() {
      if (typeof this.rules === 'undefined') {
        return [v => !!v || "Er moet een waarde geselecteerd worden"]
      }
      return this.rules
    },
    displayProjectSelect() {
      return !this.all || (this.value.client && this.value.client?.id !== "All")
    },
    getColsByHorizontal() {
      if (this.horizontal) {
        return "6"
      } else {
        return "12"
      }
    }
  },
  watch: {
    clients: {
      immediate: true,
        handler() {
        this.doAutoFills()
      }
    },
    autoFillClientId: {
      immediate: true,
        handler() {
      this.doAutoFills()
        }
    },
    autoFillProjectId: {
      immediate: true,
        handler() {
      this.doAutoFills()
        }
    },
    includeArchived: {
      immediate: true,
      handler(newValue) {
        if (newValue == null) {
          return
        }
        if(this.showAllClients){
          this.promises.push(this.$store.dispatch('clientsModule/fetchClientsThatHaveProjectsForOrganization').then(result => this.fetchedClients = result))
        }else{
          this.promises.push(this.$store.dispatch('clientsModule/fetchActiveClientsForUser', this.getUserId()).then(result => this.fetchedClients = result))

        }
      }
    }
  }
}
</script>
