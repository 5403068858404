<template>
  <v-row :dense="dense">
    <v-col :cols="cols">
      <PromiseContainer :promises.sync="promises">
        <template v-slot:default>
          <v-autocomplete v-model="modelValue" :item-value="v => v"
                    :item-text="item => item.firstName + ' ' + item.lastName"
                    :items="users" label="Personeelslid"
                    :rules="getRules" :required="required"
                    autofocus v-on="$listeners" v-bind="$attrs" class="notranslate">
                      <template v-slot:item="slotProps">
                        <v-list-item-content :class="{'notranslate': slotProps.item.id != 'All'}" v-text="slotProps.item.firstName + ' ' + slotProps.item.lastName"></v-list-item-content>
                      </template>
                    </v-autocomplete>
        </template>
      </PromiseContainer>
    </v-col>
  </v-row>
</template>

<script>
import PromiseContainer from "@/components/shared/PromiseContainer.vue"
import { sortListAlphabeticallyByParameter } from "@/shared/utils/CollectionUtils"

export default {
  name: "CustomUserSelect.vue",
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: Object,
    rules: Array,
    cols: {
      type: String,
      default: '5'
    },
    all: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autoFillUserId: {
      type: String,
      required: false,
      default: null
    },
    showInActive: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update'],
  data() {
    return {
      fetchedUsers: [],
      promises: [this.$store.dispatch('usersModule/fetchUsers')
        .then(data => {
          this.fetchedUsers = data
          this.autoFillUser(this.autoFillUserId)
        })]
    }
  },
  components: {
    PromiseContainer
  },
  computed: {
    modelValue: {
        get() {
            return this.value
        },
        set(newValue) {
            this.$emit('update', newValue)
        }
    },
    users() {
      const filterUsers = this.filterUsers([...this.fetchedUsers])
      const sortedUsers = sortListAlphabeticallyByParameter(filterUsers, "firstName")
      if (this.all) {
        sortedUsers.unshift({firstName: "Alle", lastName: "", email: "All"})
      }
      return sortedUsers
    },
    getRules() {
      if (typeof this.rules === 'undefined' && this.required) {
        return [v => !!v || "Gebruiker moet ingevuld worden"]
      }
      return this.rules
    },
  },
  methods: {
    autoFillUser(newValue) {
        if (newValue && (!this.modelValue || this.modelValue?.email == "All")) {
          this.modelValue = this.users.find(user => user.id === newValue)
        } else if (!newValue && this.all) {
          this.modelValue = {firstName: "Alle", lastName: "", email: "All"};
        }
    },
    filterUsers(users) {
      if (this.showInActive) {
        return users
      }
      return users.filter(user => user.active)
    },
  },
  watch: {
    autoFillUserId: {
      immediate: true, 
      handler(newValue) {
        this.autoFillUser(newValue)
      }
    }
  }
}
</script>
